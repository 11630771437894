import { computed } from 'vue';
import { useStore } from 'vuex-composition-helpers';
import { nameSpace as UserDataNameSpace } from '@/vue/stores/UserData/UserDataStore';

export const useUserData = () => {
  const store = useStore();

  const userData = computed(() => store.getters[`${UserDataNameSpace}/getUserData`]);
  const userGeolocation = computed(
    () => store.getters[`${UserDataNameSpace}/getUserGeolocation`],
  );

  const setUserData = payload => store.dispatch(`${UserDataNameSpace}/setUserData`, payload);
  const setUserGeolocation = payload => store.dispatch(`${UserDataNameSpace}/setUserGeolocation`, payload);

  return {
    userData,
    userGeolocation,
    setUserData,
    setUserGeolocation,
  };
};
